import React, { useContext } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import BlockContent from "@sanity/block-content-to-react"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../../constants"
import { BrinkContext } from "../context/BrinkContext"

const Contianer = styled.div`
  padding: 0 3rem 3rem;
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto 2rem;

  ${MEDIA_MIN_MEDIUM} {
    padding: 0 0 6rem;
  }

  div {
    p {
      margin: 0;
      padding: 1rem 0;
      font-size: 1.4rem;
    }

    h2,
    h3 {
      font-family: "Josefin Sans", "sans-serif";
      line-height: 2rem;
      margin: 3rem 0 1rem;
      color: ${(p) => p.theme.colors.secondary};
    }

    a {
      color: ${(p) => p.theme.colors.black};
    }
  }
`

const Title = styled.h1`
  font-size: 2.4rem;
  text-transform: uppercase;
  margin-bottom: 3rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 3rem;
  }
`

const SubTitle = styled.h2`
  font-size: 1.6rem;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.black};
  text-align: center;
  margin: -1rem 0 3rem;
  font-family: "Josefin Sans", "sans-serif";

  ${MEDIA_MIN_MEDIUM} {
    font-size: 2rem;
  }
`

export default ({ data }) => {
  const { languageCode } = useContext(BrinkContext)
  const { title, subTitle, _rawText } = data
  const textBlocks = _rawText[languageCode] || _rawText.en

  return (
    <Contianer>
      {title && <Title>{title[languageCode] || title.en}</Title>}
      {subTitle && <SubTitle>{subTitle[languageCode] || subTitle.en}</SubTitle>}
      <div>
        {textBlocks.text.map((text) => (
          <BlockContent key={uuidv4()} blocks={text} />
        ))}
      </div>
    </Contianer>
  )
}
