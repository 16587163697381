import React, { useContext } from "react"
import { graphql } from "gatsby"
import { v4 as uuidv4 } from "uuid"
import styled from "styled-components"
import { BrinkContext } from "../components/context/BrinkContext"
import Layout from "../components/Layout"
import Text from "../components/widgets/Text"
import Hero from "../components/widgets/Hero"
import ProductSlider from "../components/widgets/ProductSlider"
import Entry from "../components/widgets/Entry"
import Breadcrumbs from "../components/ui/Breadcrumbs"

const Container = styled.div`
  padding: 0 3rem;
`

export default ({ data: { sanityCmsPage }, pageContext }) => {
  const { languageCode } = useContext(BrinkContext)
  const { widgets, pageTitle, pageDescription } = sanityCmsPage

  return (
    <Layout
      meta={{
        title: pageTitle[languageCode] || pageTitle.en,
        description: pageDescription[languageCode] || pageDescription.en
      }}
      invertedHeader
      pageContext={pageContext}
    >
      <Breadcrumbs> {pageTitle[languageCode] || pageTitle.en}</Breadcrumbs>
      <Container>
        {widgets &&
          widgets.length &&
          widgets.map((widget) => {
            switch (widget.__typename) {
              case "SanityTextWidget":
                return <Text key={uuidv4} data={widget} />
              case "SanityHero":
                return <Hero key={uuidv4} data={widget} />
              case "SanityProductSlider":
                return (
                  <ProductSlider
                    key={uuidv4}
                    title={widget.title}
                    products={widget.products}
                    columns="4"
                  />
                )
              case "SanityGrid":
                return <Entry key={uuidv4} data={widget} />
              default:
                return null
            }
          })}
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($sanityPageId: String!) {
    sanityCmsPage(_id: { eq: $sanityPageId }) {
      pageTitle {
        en
        zh
      }
      pageDescription {
        en
        zh
      }
      widgets {
        ...TextWidget
        ...Grid
        ...Hero
        ...ProductSlider
      }
    }
  }
`
